.Footer {
    position: relative;
    display: flex;
    padding: 20px 0;
    bottom: 0;
    left: 3%;
    right: 4%;
    width: 95%;
    border-top-style: solid;
    border-top-color: #cecece;
    border-top-width: 2px;
}

ul {
    list-style-type: none;
    width: 100%;

}

li {
    float: left;
    width: 25%;
    text-align: left;
    padding-bottom: 2.5rem;
} 

.footer-title {
    font-weight: bold;
}

.footer-logo {
    float: left;
    height: 1rem;
    width: 1rem;
    border-radius: 0%;
    padding-top: 1px;
    padding-right: .75rem;
}