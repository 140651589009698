.row {
    display: flex;
    clear: both;
    width: 90%;
    margin: auto;
    padding: 3rem;
    border-bottom-style: solid;
    border-bottom-color: #cecece;
    border-bottom-width: 1px;
}

.row3 {
    display: flex;
    clear: both;
    width: 90%;
    margin: auto;
    padding: 3rem;
}
.column {
    float:left;
    width: 10%;
    margin-left: 3rem;
    padding: 10px;
    padding-left: 10rem;
    text-align: left;
}
.dateColumn {
    float:left;
    width:10%;
    padding-right: 0rem;
    padding-top: 1.3rem;
    padding-bottom: 1rem;
    padding-left: 20rem;
    text-align: left;
    font-family: sans-serif;
    font-size: .8rem;
}
.column3 {
    float:left;
    width: 30%;
    padding: 10px;
    padding-left: 0rem;
    text-align: left;
    font-size: .8rem;
}

.accomplishments {
    list-style-type: disc;
}

.bullet {
    float: auto;
    width: auto;
    padding: 5px;
}

.hide {
    padding-bottom: 1.3rem;
}

.titleRow {
    display: flex;
    clear: both;
    width: 90%;
    margin: auto;
    font-size: 1.5rem;
}

