.profile-pic {
    width: 50%;
    padding-top: 6rem;
    padding-bottom: 9rem;
    padding-right: 3rem;
    float: left;
}
.profile {
    width: 30%;
    padding-top: 6rem;
    padding-bottom: 9rem;
    float: left;
    text-align: left;
}

.home {
    width: 100%;
}

.hello {
    font-size: 5rem;
}

h5 {
    width:50%;
    font-size:1.5rem;

}

img {
    float: right;
    width: 30rem;
    height: 30rem;
    border-radius: 50%;

}